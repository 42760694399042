import React , {useRef} from 'react'
import "./Join.css"
import emailjs from "@emailjs/browser" 

const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_mgmp9m4', 'template_7dp01wu', form.current, 'dwm9TVzStgJ5f-0hV')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='Join' id="join-us">
        
        <div className="left-j">
            <div>
                <span>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span>WITH US?</span>
            </div>
        </div>

        <div className="right-j">

            <form ref={form} className='email-container'>
                <input type="email"  name="user_email" placeholder='Enter Your Email Address'/>
                <button className='btn btn-j'>Join Now</button>
            </form>
        </div>

    </div>
  )
}

export default Join